<template>
  <div>
    <el-dialog
      :title="detailData.outlet_name_mon"
      :visible="isShow"
      @close="close"
      :before-close="close"
      width="50%"
      :close-on-click-modal="onClose"
    >
      <div class="panel payments-container" v-loading="loading">
        <el-row style="margin-bottom:1%;" :gutter="0">
          <el-col :span="6">Холбогдох и-мэйл :</el-col>
          <el-col :span="12">{{ detailData.email }}</el-col>
        </el-row>
        <el-row style="margin-bottom:1%;" :gutter="0">
          <el-col :span="6">Төлөх дүн :</el-col>
          <el-col :span="12">{{ detailData.pay_total }}</el-col>
        </el-row>
        <el-row style="margin-bottom:1%;" :gutter="0">
          <el-col :span="6">Төлсөн эсэх :</el-col>
          <el-col :span="12">
            <span
              :style="
                detailData.pay_status === 'paid' ? 'color: green' : 'color:red'
              "
            >
              {{
                detailData.pay_status === "paid"
                  ? "Төлсөн"
                  : "Төлбөр хүлээгдэж буй"
              }}
            </span>
          </el-col>
        </el-row>
        <el-row
          style="margin-bottom:1%;"
          v-if="detailData.payed_at"
          :gutter="0"
        >
          <el-col :span="6">Төлсөн огноо :</el-col>
          <el-col :span="12">{{ detailData.payed_at }}</el-col>
        </el-row>
        <el-row
          style="margin-bottom:1%;"
          v-if="detailData.ended_date"
          :gutter="0"
        >
          <el-col :span="6">Дуусах огноо :</el-col>
          <el-col :span="12">{{ detailData.ended_date }}</el-col>
        </el-row>
        <div
          style="border: 1px solid lightgrey; border-radius: 10px; padding: 2%;"
          v-if="
            detailData.service_type === 'package' ||
              detailData.service_type === 'single_service'
          "
        >
          <el-row
            style="padding:1%;"
            :gutter="20"
            v-for="(item, itemIndex) in detailData.promotion_items.filter(
              items => items.type === 'in_package' || items.type === 'single'
            )"
            :key="itemIndex"
          >
            <el-col
              style="overflow-wrap: normal; word-break: normal; padding:1%; display:flex; align-items:center;"
              :span="8"
            >
              {{ item.promotion_product_name }} :
            </el-col>
            <el-col :span="12">
              <el-checkbox size="small" v-model="item.is_done" border
                >Хийгдсэн эсэх</el-checkbox
              >
            </el-col>
          </el-row>
        </div>
        <el-table
          v-else-if="detailData.service_type === 'banner'"
          :data="[this.promotionItems]"
        >
          <el-table-column label="Зураг">
            <template slot-scope="scope">
              <div class="textClass">
                <el-upload
                  v-model="scope.row.content.source_url"
                  class="avatar-uploader"
                  action=""
                  style="margin-top:3%;"
                  fit="cover"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="file => uploadImage(file)"
                >
                  <el-image
                    v-if="scope.row.content.source_url"
                    :src="scope.row.content.source_url"
                    class="avatar"
                    style="width:150px; height:80px;"
                  />
                  <span v-else>
                    <i
                      class="el-icon-plus"
                      style="display:flex; align-items:center; justify-content:center; width:120px; height:80px;"
                      >&nbsp;Ток токooр бэлдүүлэх</i
                    >
                  </span>
                </el-upload>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Гарчиг">
            <template slot-scope="scope">
              {{ scope.row.content.title }}
            </template>
          </el-table-column>
          <el-table-column label="Үйлчилгээ">
            <template slot-scope="scope">
              {{ scope.row.content.service }}
            </template>
          </el-table-column>
          <el-table-column label="Үнэ">
            <template slot-scope="scope">
              {{ scope.row.item_price }}
            </template>
          </el-table-column>
          <el-table-column label="Зөвшөөрөх эсэх">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_approved"
                >Зөвшөөрөх</el-checkbox
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">Хаах</el-button>
        <el-button @click="save(detailData.promotion_id)" type="success"
          >Хадгалах</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from "../../helpers/services";
import axios from "axios";
import { getBase64 } from "../../helpers/helper";
export default {
  props: {
    isShow: Boolean,
    id: String,
    close: Function
  },
  created() {
    this.getOneStory();
  },
  methods: {
    async getOneStory() {
      this.loading = true;
      await services.getOnePromotion(this.id).then(res => {
        if (res.data.status === "success") {
          this.detailData = res.data.data;
          this.promotionItems = res.data.data.promotion_items[0];
        } else {
          this.$message({
            message: res.data.message,
            type: "warning"
          });
        }
        this.loading = false;
      });
    },
    async save(id) {
      await services.updatePromotion(id, this.detailData).then(res => {
        if (res.data.status === "success") {
          this.$message({
            type: "success",
            message: "Амжилттай хадгаллаа..."
          });
          this.close();
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      });
    },
    async uploadImage(file) {
      try {
        this.loading = true;
        if (!["image/png", "image/jpeg", "image/jpg"].includes(file.raw.type)) {
          throw new Error(
            "Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой."
          );
        }
        const image = new Image();
        image.src = URL.createObjectURL(file.raw);
        const promise = new Promise(resolve => {
          image.onload = function() {
            resolve({ width: image.width, height: image.height });
          };
        });
        const result = await promise;
        if (result.width !== 1200 && result.height !== 600) {
          this.loading = false;
          throw new Error("Зураг 1200х600px байх ёстой.");
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) throw new Error("Зураг 1MB-с бага байх ёстой.");
        await getBase64(file.raw).then(async res => {
          const payload = {
            base64: res
          };
          await axios
            .post("https://rest.toktok.mn/other/profileImageUploader", payload)
            .then(response => {
              if (response.data.status === "success") {
                this.promotionItems.content.source_url = response.data.data;
                this.loading = false;
              }
            });
        });
      } catch (error) {
        this.loading = false;
        this.$message.error(error.message);
      }
    }
  },
  data() {
    return {
      loading: false,
      onClose: false,
      isVisibleDetail: false,
      detailData: {},
      promotionItems: {}
    };
  }
};
</script>
<style scoped>
.textClass {
  width: 150px;
  height: 80px;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid;
}
</style>
