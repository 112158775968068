<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Marketing
      </h3>
    </div>
    <div class="panel" style="width:70%; margin-left: 15%;">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-select
            clearable
            v-model="service_type"
            placeholder="Төрөл"
            size="medium"
          >
            <el-option
              v-for="(type, typeIndex) in serviceTypeList"
              :key="typeIndex"
              :label="type.name"
              :value="type.value"
            />
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="outlet_id"
            placeholder="Харилцагч"
            filterable
            clearable
            size="medium"
          >
            <el-option
              v-for="(outlet, outletIndex) in allOutlets"
              :key="outletIndex"
              :label="outlet.full_name_eng"
              :value="outlet._id"
            />
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="pay_status"
            placeholder="Төлсөн эсэх"
            filterable
            clearable
            size="medium"
          >
            <el-option
              v-for="(pay, payIndex) in payList"
              :key="payIndex"
              :label="pay.name"
              :value="pay.value"
            />
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="status"
            placeholder="Идэвхитэй эсэх"
            filterable
            clearable
            size="medium"
          >
            <el-option
              v-for="(list, listIndex) in activeList"
              :key="listIndex"
              :label="list.name"
              :value="list.value"
            />
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button @click="getPromotion" size="medium" type="primary"
            >Хайх</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="promotionList" v-loading="loading" style="width: 100%">
        <el-table-column prop="service_type" label="Төрөл">
          <template slot-scope="scope">
            <span v-if="scope.row.service_type === 'package'"
              >Багц үйлчилгээ</span
            >
            <span v-else-if="scope.row.service_type === 'banner'"
              >Сурталчилгаа</span
            >
            <span v-else-if="scope.row.service_type === 'single_service'"
              >Дан үйлчилгээ</span
            >
            <span v-else>{{ scope.row.service_type }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outlet_name_mon" label="Харилцагчийн нэр">
        </el-table-column>
        <el-table-column prop="pay_total" label="Төлөх дүн"> </el-table-column>
        <el-table-column prop="pay_status" label="Төлсөн эсэх">
          <template slot-scope="scope">
            <span
              :style="
                scope.row.pay_status === 'paid' ? 'color: green' : 'color:red'
              "
              >{{
                scope.row.pay_status === "paid"
                  ? "Төлсөн"
                  : "Төлбөр хүлээгдэж буй"
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="280px" align="right" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="info"
              @click="showDetail(scope.row.promotion_id)"
              >Дэлгэрэнгүй</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt20 text-right"
        background
        @size-change="handleSizeChangeLog"
        @current-change="handleCurrentChangeLogs(pagination.currentPage)"
        :current-page.sync="pagination.currentPage"
        :page-size="pagination.size"
        layout="sizes, prev, pager, next"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
    <client-product-detail
      v-if="isVisible"
      :isShow="isVisible"
      :id="promotionId"
      :close="close"
    ></client-product-detail>
  </div>
</template>
<script>
import ClientProductDetail from "./ClientProductDetail.vue";
import service from "../../helpers/services";
export default {
  components: {
    ClientProductDetail
  },
  created() {
    this.getPromotion();
    const payload = {
      includeFields: ["full_name_eng"],
      is_verified: true,
      pageSize: 10000,
      fromRecord: 0
    };
    service.getOutlets(payload).then(data => {
      if (data.data.status === "success") {
        this.allOutlets = data.data.data;
      } else {
        this.$message({
          message: data.data.message,
          type: "warning"
        });
      }
    });
  },
  methods: {
    async getPromotion() {
      this.loading = true;
      const payload = {
        pay_status: this.pay_status,
        size: this.pagination.size,
        from: this.pagination.from,
        service_type: this.service_type,
        outlet_id: this.outlet_id,
        status: this.status
      };
      await service.getPromotion(payload).then(res => {
        if (res.data.status === "success") {
          this.promotionList = res.data.data;
          this.pagination.total = res.data.total;
        } else {
          this.$message({
            message: res.data.message,
            type: "warning"
          });
        }
        this.loading = false;
      });
    },
    showDetail(id) {
      this.promotionId = id;
      this.isVisible = true;
    },
    close() {
      this.getPromotion();
      this.isVisible = false;
    },
    handleSizeChangeLog(val) {
      this.pagination.size = val;
      this.getPromotion();
    },
    handleCurrentChangeLogs(val) {
      this.pagination.currentPage = val;
      this.pagination.from = (val - 1) * this.pagination.size;
      this.getPromotion();
    }
  },
  data() {
    return {
      loading: false,
      pay_status: "",
      service_type: "",
      isVisible: false,
      outlet_id: "",
      promotionList: [],
      promotionId: "",
      allOutlets: [],
      payList: [
        {
          name: "Төлсөн",
          value: "paid"
        },
        {
          name: "Төлбөр хүлээгдэж буй",
          value: "not_paid"
        }
      ],
      serviceTypeList: [
        {
          name: "package",
          value: "package"
        },
        {
          name: "banner",
          value: "banner"
        },
        {
          name: "sms",
          value: "sms"
        },
        {
          name: "notification",
          value: "notification"
        },
        {
          name: "SingleService",
          value: "single_service"
        }
      ],
      pagination: {
        total: 0,
        from: 0,
        currentPage: 1,
        size: 10
      },
      status: "",
      activeList: [
        {
          name: "Идэвхитэй",
          value: "active"
        },
        {
          name: "Идэвхигүй",
          value: "in_active"
        }
      ]
    };
  }
};
</script>
<style scoped>
.image {
  width: 120px;
  height: 100px;
}
</style>
